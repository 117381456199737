* {
  margin: 0;
  padding: 0;
  box-sizing: border-box;
}

body {
  font-family: "Open Sans";
  color: $darkGrey;
}

.logo2 {
  width: 58px;
  height: 47px;
}

.hp {
  .bestBrokers__image img {
    width: 100%;
    object-fit: cover;
    object-position: center top;
    height: 20rem;
  }

  .bestBrokers__image {
    margin-left: -1.8rem;
    margin-top: -1.8rem;
    width: calc(100% + 3.6rem);
    margin-bottom: 1.8rem;
  }

  .bestBrokers__card .heading2 {
    padding-bottom: .5rem;
  }

  @include media-breakpoint-up(sm) {
    .bestBrokers__image {
      margin-bottom: 1rem;
      margin-left: -1.8rem;
      margin-top: -1.8rem;
      width: calc(100% + 3.6rem);
    }
    .bestBrokers__heading {
      min-height: 4rem;
      position: relative;
      margin-left: -1.8rem;
      padding-left: 1.8rem;
      padding-bottom: 0;
      padding-right: 1.8rem;
      width: auto;
      background: white;
    }
  }
}

.hp .bestBrokers {
  @include media-breakpoint-down(sm) {
    padding: {
      left: 15px;
      right: 15px;
    };
  }

  &__image img {
    //height: 22rem;
  }
}

.services {
  &__count {
    display: flex;
    align-items: center;
    justify-content: space-around;
    flex-wrap: wrap;
    padding-bottom: 1.5rem;

    > div {
      width: 45%;
    }

    @include media-breakpoint-up(sm) {
      > div {
        padding: 1rem;
      }
    }
  }

  &__count h2, &__count .countValue {
    font-family: "PT Serif";
    height: 2.125rem;
    color: $darkGrey;
    font-size: 1.625rem;
    font-weight: 700;
    line-height: 3.063rem;
    @include media-breakpoint-up(sm) {
      font-size: 2.875rem;
    }
  }

  &__count h3, &__count .countName {
    color: $darkGrey;
    font-size: 0.938rem;
    letter-spacing: 0.019rem;
    line-height: 1.5rem;
    font-weight: 400;
    @include media-breakpoint-up(sm) {
      font-size: 1.125rem;
      letter-spacing: 0.023rem;
      line-height: 1.75rem;
      padding-top: 10px;
    }
  }

  &__count a {
    color: $darkGrey;
    font-weight: 700;
    transition: all 300ms;

    &:hover {
      color: lighten($darkGrey, 10%);
      text-decoration: underline;

    }
  }
}

.references {
  margin: 2rem 0;
  padding: 4rem 0;
  @include media-breakpoint-up(sm) {
    margin: 6.5rem 0;
    padding: 2rem 0;
  }
}

.helper {
  background-color: #f2f2f2;
  position: relative;
  overflow: hidden;
  padding: {
    top: 5.625rem;
    bottom: 7.5rem;
  }

  img {
    position: relative;
    right: -10%;
    top: 5%;
  }

  &__body {
    padding: 0 25px;
  }

  .heading3 {
    margin-bottom: 1.875rem;
  }

  p {
    color: $darkGrey;
    font-size: 1.063rem;
    font-weight: 400;
    line-height: 1.688rem;
    margin-bottom: 2.5rem;
  }

  .button {
    display: block;
  }

  @include media-breakpoint-up(sm) {
    &__body {
      padding: 0;
    }
    .button {
      display: inline-block;
    }
  }
  @include media-breakpoint-up(xl) {
    img {
      position: relative;
      right: -35%;
      top: 5%;
    }
  }
}

.video {
  .button {
    display: block;
    margin-bottom: 0;
  }
}

.brokersDatabase {
  background-color: $lightWhite;
  overflow: hidden;
  padding: {
    top: 5.625rem;
    bottom: 5.625rem;
  }

  img {
    position: relative;
    right: -5%;
    top: -5%;
  }

  &__body {
    padding: 0 25px;
  }

  .heading3 {
    margin-bottom: 1.875rem;
  }

  .button {
    display: block;
  }

  p {
    color: $darkGrey;
    font-size: 1.063rem;
    font-weight: 400;
    line-height: 1.688rem;
    margin-bottom: 2.5rem;
  }

  @include media-breakpoint-up(sm) {
    &__body {
      padding: 0;
    }
    .button {
      display: inline-block;
    }
  }
  @include media-breakpoint-up(xl) {
    img {
      position: relative;
      right: -25%;
      top: -15%;
    }
  }
}


.partnersServices {
  background-color: $lightGreen;
  overflow: hidden;
  padding: {
    top: 5.625rem;
    bottom: 5.625rem;
  }

  img {
    position: relative;
    right: -15%;
    top: 5%;
    width: 80%;
  }

  &__body {
    padding: 0 25px;
  }

  .heading2 {
    margin-bottom: 2.5rem;
  }

  .heading3 {
    margin-bottom: 1.875rem;
    color: $darkGrey;
    font-size: 1.5rem;
    font-weight: 400;
    line-height: 2.313rem;
  }

  .button {
    padding: 1rem 1.5rem;
    display: block;
  }

  p {
    color: $darkGrey;
    font-size: 1.063rem;
    font-weight: 400;
    line-height: 1.688rem;
    margin-bottom: 2.5rem;
  }

  @include media-breakpoint-up(sm) {
    img {
      width: 100%;
    }
    .heading2 {
      margin-bottom: 3.75rem;
    }
    .heading3 {
      font-size: 2rem;
      line-height: 2.938rem;
    }
    &__body {
      padding: 0;
    }
    .button {
      display: inline-block;
    }
  }

  @include media-breakpoint-up(xl) {
    img {
      position: relative;
      right: -35%;
      top: 0%;
    }
  }
}

.category .container {
  min-height: 140px;
}


.blog {
  .category .searchForm input {
    height: 2.75rem;
    font-size: 0.938rem;
    font-weight: 400;
    letter-spacing: 0.019rem;
    background: white;
  }

  .category {
    padding-bottom: 3.875rem;

    &:after {
      content: '';
      position: absolute;
      height: 1px;
      left: 0;
      right: 0;
      background: #dedede;
    }

    .heading3 {
      color: $darkGrey;
      font-size: 0.938rem;
      font-weight: 700;
      letter-spacing: 0.019rem;
      line-height: 1.5rem;
      margin-bottom: 1rem;
    }

    .searchForm input, .searchForm label {
      width: 100%;
    }
  }

  .news .heading2 {
    margin: .8rem 0;
  }

  .news .playIco {
    position: absolute;
    top: 50%;
    left: 50%;
    transform: translate(-50%, -50%);
  }

  .news__mainPost__body {
    padding: 0;
  }

  .author {
    display: inline-block;
  }

  @include media-breakpoint-up(sm) {
    .searchForm input, .searchForm label, .form-control {
      width: 20rem;
    }
    .news__mainPost .col-md-6 {
      margin-bottom: 6rem;
    }
    .news__mainPost {
      margin-bottom: 0;
    }
  }

  .poster {
    margin-top: 3.875rem;
  }
}

.text-box {
  img, video {
    max-width: 100%;
  }

  .lead {
    font-size: 0.938rem;
    font-weight: 700;
    line-height: 1.563rem;
  }

  h1 {
    font-family: "PT Serif";
    font-size: 1.625rem;
    font-weight: 700;
    letter-spacing: 0.033rem;
    line-height: 2.313rem;
    margin-bottom: 20px;
  }

  h2 {
    font-size: 1rem;
    font-weight: 700;
    letter-spacing: 0.038rem;
    line-height: 1.5rem;
    margin-top: 20px;
  }

  h3 {
    font-size: 0.9rem;
    font-weight: 700;
    letter-spacing: 0.038rem;
    line-height: 1.5rem;
    margin-top: 20px;
  }

  p {
    font-size: 0.938rem;
    font-weight: 400;
    line-height: 1.563rem;
  }

  a {
    text-decoration: underline;
    color: $darkGrey;

    &:hover {
      text-decoration: unset;
      color: $darkGrey;
    }
  }

  ul {
    margin-left: 20px;
    padding: 0;
  }

  @include media-breakpoint-up(sm) {
    h1 {
      font-size: 2.25rem;
      line-height: 3.0rem;
      margin-bottom: 3.0rem;
    }
    h2 {
      font-size: 1.25rem;
      line-height: 2rem;
      margin-bottom: 2rem;
      margin-top: 2rem;
    }
    h3 {
      font-size: 1rem;
      line-height: 1.5rem;
      margin-bottom: 0.5rem;
      margin-top: 1.5rem;
    }
    .lead p {
      font-size: 1rem;
      line-height: 1.8rem;
      margin-bottom: 1.8rem;
    }
    p {
      font-size: 1rem;
      line-height: 1.6rem;
      margin-bottom: 1rem;
    }
  }
}

.blog-detail {
  color: $darkGrey;

  .author {
    margin-bottom: 1.2rem;

    a {
      color: $darkGrey;

      &:hover {
        color: $darkGrey;
        text-decoration: underline;
      }
    }

    span {
      font-size: 0.813rem;
      font-weight: 600;
      letter-spacing: 0.033rem;
      line-height: 2.25rem;
      margin-bottom: 1.2rem;
      text-transform: uppercase;
    }
  }

  .video-container video {
    max-width: 57.5rem;
  }

  &__body .info-bar {
    padding-top: 20px;
  }

  .image-container img {
    width: 100%;
    margin-bottom: 5px;
  }

  .button {
    margin-bottom: .8rem;
  }

  @include media-breakpoint-up(lg) {
    .top .author-detail > div {
      max-width: 350px;
    }
  }
  @include media-breakpoint-up(md) {
    .author {
      display: none;
    }

    .image {
      height: 37.5rem;
    }
    &__body {
      background: white;
      padding: 0 15px;
      margin-top: -5rem;
      position: relative;
      max-width: 920px;
    }
    .text-box {
      padding: 0 4rem;
    }
    .info-bar {
      padding: 2rem 4rem;
    }
    .image-container img {
      margin-right: 5px;
      width: 45%
    }
    .top .author-detail {
      margin-bottom: 2rem;
      margin-top: 4.5rem;
      padding: {
        top: 4rem;
        left: 1.25rem;
        right: 1.25rem;
      }

      .avatar {
        width: 7.5rem;
        height: 7.5rem;
        object-fit: cover;
        margin-bottom: 1rem;
        border-radius: 50%;
        transition: all 300ms;
      }

      .heading2 {
        font-size: 2.25rem;
        font-weight: 700;
        line-height: 3.063rem;
        font-family: "PT Serif";
      }

      .heading4 {
        font-size: 0.938rem;
        letter-spacing: 0.038rem;
        line-height: 2.25rem;
      }

      span {
        font-size: 0.813rem;
        font-weight: 600;
        letter-spacing: 0.033rem;
        line-height: 2.25rem;
        text-transform: uppercase;
        display: block;
        margin-bottom: 1rem;
      }
    }
  }
}

.video-detail .blog-detail {
  @include media-breakpoint-up(sm) {
    &__body {
      margin-top: 0;
    }
  }
}

@include media-breakpoint-down(sm) {
  .video-detail .top {
    height: 15rem;

    .image {
      height: 100%;
    }
  }
}

.author-question {
  background-color: #f2f2f2;
  padding: {
    top: 2.625rem;
    bottom: 3.75rem;
  }
  @include media-breakpoint-up(sm) {
    padding: {
      top: 2.625rem;
      bottom: 3.75rem;
    }
  }

  .heading2 {
    margin-bottom: 5rem;
  }

  .form-group {
    color: $darkGrey;
    font-size: 0.938rem;
    font-weight: 400;
    letter-spacing: 0.019rem;

    ::placeholder {
      color: $darkGrey;
      font-size: 0.938rem;
      font-weight: 400;
      letter-spacing: 0.019rem;
    }

    textarea {
      height: 8rem;
      border-radius: 0.313rem;
      border: 0.063rem solid $darkGrey;
      background-color: #f2f2f2;
      opacity: 0.6;
      padding: 17px;
    }

    input {
      height: 3.375rem;
      border-radius: 0.313rem;
      border: 0.063rem solid $darkGrey;
      background-color: #f2f2f2;
      opacity: 0.6;
      padding: 17px;
    }

    @include media-breakpoint-up(sm) {
      textarea {
        min-height: 212px;
        height: unset;
      }
    }
  }
}

.youMayLikeIt .heading2--primary {
  margin-bottom: 2.5rem;
}

.youMayLikeIt .video__post .heading2 {
  font-family: "PT Serif";
  font-size: 0.938rem;
  font-weight: 700;
  line-height: 1.438rem;
  margin-top: 0;
}

.youMayLikeIt .author span {
  font-size: 0.813rem;
  font-weight: 400;
  letter-spacing: 0.016rem;
  text-transform: unset;
}

.youMayLikeIt .video__post {
  border-bottom: 1px solid #eeeeee;
  padding-bottom: 1rem;
  margin-bottom: 1rem;
}

@include media-breakpoint-up(sm) {
  .youMayLikeIt .video__post .heading2 {
    color: $darkGrey;
    font-size: 1.25rem;
    letter-spacing: 0.025rem;
    line-height: 1.875rem;
    margin: .8rem 0;
  }
  .youMayLikeIt .video__post {
    margin-bottom: 1rem;
    border: 0;
  }
}

.about {
  @include media-breakpoint-up(sm) {
    .banner .heading1 {
      font-size: 2rem;
      line-height: 2.938rem;
    }
  }

  .body {
    margin: 2.625rem auto;
    @include media-breakpoint-up(sm) {
      margin: 5.625rem auto;
    }
  }

  .body .container-fluid {
    margin: 2.625rem auto;
    padding: 2.625rem 0;
    @include media-breakpoint-up(sm) {
      margin: 5.625rem auto;
      padding: 5.625rem 0;
    }
  }

  .body img {
    margin-bottom: 2rem;
  }

  .contact {
    padding: 20px;
    color: $darkGrey;
  }

  .contact p {
    font-size: 0.813rem;
    font-weight: 400;
    line-height: 1.375rem;
    @include media-breakpoint-up(sm) {
      font-size: 0.938rem;
      line-height: 1.563rem;
    }
  }

  .contact a {
    font-size: 1.5rem;
    font-weight: 400;
    line-height: 2.25rem;
    text-decoration: underline;
    color: $darkGrey;
  }

  .bestBrokers__body {
    color: $darkGrey;
    background: unset;
    padding: 0;
    background: white;

    .heading2 {
      text-align: center;
      margin: 0;
      padding: 0;

      font-size: 0.938rem;
      line-height: 1.563rem;
      font-weight: 700;
    }

    .heading3 {
      font-size: 0.938rem;
      line-height: 1.563rem;
      font-weight: 400;
    }

    a {
      color: $darkGrey;
      font-size: 0.938rem;
      font-weight: 400;
      line-height: 2.188rem;
      text-decoration: underline;
    }

    @include media-breakpoint-up(sm) {
      .heading2 {
        font-size: 1.063rem;
        line-height: 1.813rem;
      }
      .heading3 {
        font-size: 1.063rem;
        line-height: 1.813rem;
      }

    }
  }
}

footer {
  width: 100%;

  &:before {
    content: '';
    position: absolute;
    height: 1px;
    left: 0;
    right: 0;
    background: #dedede;
  }

  img {
    margin-top: 5rem;
  }

  .footer__body {
    .info {
      margin-top: 1.75rem;
      margin-bottom: 2.8rem;
    }
  }

  margin-top: 2.5rem;

  .footer {
    &__body {
      padding: 0 18px;
    }

    padding-top: 3.75rem;

    .info {
      color: $lightBlack;
      font-size: 0.75rem;
      font-weight: 400;
      letter-spacing: 0.015rem;
      line-height: 1.125rem;
      @include media-breakpoint-up(sm) {
        margin: {
          top: 3.875rem;
          bottom: 5.625rem;
        };
      }
    }

    .info a {
      color: $blue;
      text-decoration: underline;
      transition: all 300ms;
    }

    .info a:hover {
      color: darken($blue, 10%)
    }

    .copy {
      height: 2.188rem;
      opacity: 0.6;
      color: $lightBlack;
      font-size: 0.75rem;
      font-weight: 400;
      letter-spacing: 0.015rem;
      line-height: 1.125rem;
    }

    .copy a {
      color: $lightBlack;
      text-decoration: underline;
      transition: all 300ms;
      padding-bottom: 2rem;
    }

    .copy a:hover {
      color: darken($lightBlack, 10%)
    }

    ul {
      list-style: none;
      padding: 0;
      margin: 0;
    }

    li {
      margin: .75rem 0;
    }

    li a {
      color: $darkGrey;
      font-size: 0.938rem;
      font-weight: 600;
      letter-spacing: 0.019rem;
      line-height: 2.25rem;
      padding: .75rem 0;
    }
  }

  @include media-breakpoint-up(sm) {
    .footer {
      padding-top: 7.8rem;
    }
  }
}


@include media-breakpoint-up(sm) {
  .bestBrokers .container {
    margin-bottom: 4rem;
  }
}

.brokers .country {
  position: relative;
  color: $darkGrey;
  padding-bottom: 1.625rem;
  margin-bottom: 1.625rem;

  .searchForm input {
    height: 2.75rem;
    font-size: 0.938rem;
    font-weight: 400;
    letter-spacing: 0.019rem;
    background: white;
  }

  &:after {
    content: '';
    position: absolute;
    height: 1px;
    left: 0;
    right: 0;
    bottom: 0;
    background: #dedede;
  }

  ul {
    padding: 0;
    margin: 0;
    list-style: none;
  }

  li {
    margin-right: .8rem;
    display: inline-block;
  }

  a {
    font-size: 0.813rem;
    font-weight: 400;
    line-height: 1.563rem;
    color: $darkGrey;
    transition: all 300ms;

    &:hover {
      text-decoration: underline;
    }
  }

  .heading2 {
    font-size: 0.938rem;
    font-weight: 700;
    letter-spacing: 0.019rem;
    line-height: 1.5rem;
  }

  @include media-breakpoint-up(sm) {
    padding-bottom: 3.625rem;
    margin-bottom: 3.625rem;

    a {
      font-size: 0.938rem;
      font-weight: 400;
      line-height: 2.25rem;
    }
    .heading2 {
      font-size: 1.25rem;
      font-weight: 700;
      letter-spacing: 0.025rem;
      line-height: 2.25rem;
    }
  }
}

.bestBrokers .bestBrokers__body {
  padding: 0;
}

.brokers .bestBrokers .container-secondary {
  padding: 0;
  @include media-breakpoint-up(sm) {
    padding: 20px;
  }
}

.bestBrokers .rounded-circle {
  box-shadow: 0 0.188rem 3.75rem rgba(0, 0, 0, 0.08);
  width: 10rem;
  height: 10rem;
  object-fit: cover;
  margin: 0 auto;
}

.broker-detail {
  //.container { margin-left: unset }
  color: $darkGrey;

  .top {
    margin-bottom: 3rem
  }

  .top__info {
    position: absolute;
    bottom: -2.5rem;
    z-index: 3;
    display: flex;

    &__body {
      padding: 2rem;
      margin-bottom: 2rem;

      .heading4 {
        font-size: 0.688rem;
        font-weight: 600;
        letter-spacing: 0.028rem;
        line-height: 1.188rem;
        text-transform: uppercase;
      }

      .heading2 {
        color: $darkGrey;
        font-family: "PT Serif";
        font-size: 2.25rem;
        font-weight: 700;
        line-height: 3.063rem;
        margin-top: 1rem;
      }
    }

    &__image .broker-profile {
      width: 10rem;
      height: 10rem;
      border: 0.313rem solid #ffffff;
      border-radius: 50%;
      box-shadow: 0 0.188rem 3.75rem rgba(0, 0, 0, 0.08);
    }
  }

  .top .image {
    position: relative;

    .broker-banner {
      width: 100%;
      height: 100%;
      object-fit: cover;
      min-height: 15.625rem;
    }

  }

  .author {
    margin: 20px 0;
  }

  .author-detail {
    margin-bottom: 2rem;
    color: $darkGrey;
    padding: {
      top: 1.875rem;
      left: 1.25rem;
      right: 1.25rem;
      bottom: 0;
    };

    .heading4 {
      font-size: 0.688rem;
      font-weight: 600;
      letter-spacing: 0.028rem;
      line-height: 1.188rem;
      text-transform: uppercase;
    }

    .heading2 {
      padding: 1.4rem 0;
      margin: 0;
      color: $darkGrey;
      font-family: "PT Serif";
      font-size: 1.25rem;
      font-weight: 700;
      letter-spacing: 0.025rem;
      line-height: 1.875rem;
    }
  }

  @include media-breakpoint-down(sm) {
    .top__info {
      left: 50%;
      transform: translateX(-50%);
      bottom: -1rem;
    }
    .top__info__body {
      display: none
    }
    .bio {
      max-height: 23.125rem;
      position: relative;
      overflow: hidden;
      padding-bottom: 2rem;

      &:after {
        content: "";
        position: absolute;
        left: 0;
        bottom: 0;
        right: 0;
        z-index: 2;
        background: url("../images/bio_bg.png");
        background-size: 100% 100%;
        height: 15rem;
        width: 100%;
      }

      .read-more {
        z-index: 3;
        display: inline-block;
        position: absolute;
        left: 50%;
        transform: translateX(-50%);
        bottom: 0;
        color: $darkGrey;
        font-size: 0.938rem;
        font-weight: 400;
        letter-spacing: 0.038rem;
        line-height: 1.5rem;
        text-decoration: underline;
      }
    }
    .show {
      max-height: unset;

      &:after {
        content: unset;
      }
    }
  }

  &__body .paragraph {
    font-size: 1.063rem;
    font-weight: 400;
    line-height: 1.688rem;
  }

  &__body .motto {
    font-family: "PT Serif";
    font-weight: 700;
    color: $darkGrey;
    font-size: 1.125rem;
    letter-spacing: 0.045rem;
    line-height: 1.813rem;
  }

  .section-padding {
    margin: 0 1.5rem;
    padding: 2rem 0;
  }

  .contactBroker {
    margin: {
      top: 6.5rem;
      bottom: 0;
    }
    padding: {
      top: 6.5rem;
      bottom: 12rem
    }
  }

  .info-bar {
    padding-top: 0;
  }

  .contactBroker {
    margin: 3rem 0;
    padding: 0;

    color: $darkGrey;

    .form-control {
      height: 3.375rem;
      font-size: 0.938rem;
      font-weight: 400;
      letter-spacing: 0.019rem;
      background: unset;
    }

    textarea {
      background: unset;
      min-height: 210px;
    }
  }

  .container-fluid {
    .heading3 {
      font-size: 1.5rem;
      font-weight: 700;
      line-height: 2.25rem;
    }
  }

  .youMayLikeIt {
    margin-top: 2rem;
  }

  @include media-breakpoint-up(xl) {
    .section-padding {
      padding: 0 5rem;
      margin: 0;
    }
  }
  @include media-breakpoint-up(md) {
    .top .image {
      .broker-profile {
        transform: unset;
        width: 13.75rem;
        height: 13.75rem;
        left: 5rem;
        bottom: -2.4rem;
      }
    }
    .info-bar {
      padding-top: .5rem;
    }
    .contactBroker {
      margin: {
        top: 6.5rem;
        bottom: 0;
      }
      padding: {
        top: 6.5rem;
        bottom: 12rem
      }
    }
    &__body .motto {
      font-size: 1.375rem;
      letter-spacing: 0.055rem;
      line-height: 2rem;
    }
    .container-fluid {
      //padding: 0;
      //max-width: 1200px;
      //margin: unset;
    }
    &__body {
      margin-top: 5.625rem;
      margin-bottom: 6.5rem;
      background: white;
      max-width: 1200px;
    }
    &__body .heading4 {
      margin-bottom: 3.125rem;
    }
    .image {
      height: 25.063rem;
    }
    .image img {
      object-fit: cover;
    }
    .author {
      display: none;
    }
    .top {
      .author-detail {
        margin-bottom: 2rem;
        margin-top: -15.6rem;
        padding: {
          top: 4rem;
        }
      }

      .author-detail .avatar {
        transform: scale(0);
        height: 0;
      }

      .author-detail #text {
        transition: all 300ms;
        transform: scale(0);
        height: 0;
      }

      .author-detail .active {
        transform: scale(1) !important;
        height: 10rem !important;
      }

      .author-detail .heading2 {
        font-size: 2.25rem;
        font-weight: 700;
        line-height: 3.063rem;
      }

      .author-detail .heading4 {
        font-size: 0.938rem;
        letter-spacing: 0.038rem;
        line-height: 2.25rem;
      }
    }
    .specialization {
      display: block;

      h5 {
        min-width: 12.5rem;
      }
    }
  }

  .broker-has-image {
    display: flex;

    .image {
      height: auto;
      flex: 1 1 100%;
      position: relative;

      &:after {
        content: "";
        position: absolute;
        bottom: 0;
        left: 0;
        right: 0;
        width: 100%;
        height: 60%;
        background-image: linear-gradient(180deg, rgba(246, 246, 246, 0) 0%, #f6f6f6 100%);
      }
    }

    .space-grey {
      display: none;
      background-color: #f6f6f6;
    }

    @include media-breakpoint-up(lg) {
      .space-grey {
        display: block;
        flex: 1 1 25%;
      }
      .image {
        height: 37rem;
        flex: 1 1 75%;
        position: relative;

        &:after {
          content: "";
          position: absolute;
          bottom: 0;
          left: 0;
          right: 0;
          width: 100%;
          height: 60%;
          background-image: linear-gradient(180deg, rgba(246, 246, 246, 0) 0%, #f6f6f6 100%);
        }
      }
    }
  }
}

.agency-detail__logo {
  width: 7.5rem;
  height: 7.5rem;
  border-radius: 50%;
  background: white;
  display: flex;
  align-items: center;
  margin-bottom: 1.5rem;
  justify-content: center;
  overflow: hidden;

  img {
    width: 100%;
  }
}

.agency-detail {
  color: $darkGrey;

  &__body {
    margin-top: 3.625rem;
  }

  .container {
    padding: 0 20px;
  }

  .top {
    background-color: #f6f6f6;
    display: flex;
    flex-direction: column;

    .image {
      flex: 1 1 50%;
    }
  }

  .author {
    margin: 20px 0;

    a {
      color: $darkGrey;
      text-decoration: underline;
    }
  }

  .author-detail {
    margin-bottom: 2rem;
    color: $darkGrey;
    padding: 30px 20px;

    .heading4 {
      font-size: 0.688rem;
      font-weight: 600;
      letter-spacing: 0.028rem;
      line-height: 1.188rem;
      text-transform: uppercase;
    }

    .heading2 {
      padding: 1.4rem 0;
      margin: 0;
      color: $darkGrey;
      font-family: "PT Serif";
      font-size: 1.25rem;
      font-weight: 700;
      letter-spacing: 0.025rem;
      line-height: 1.875rem;
    }
  }

  &__body .paragraph {
    font-size: 1.063rem;
    font-weight: 400;
    line-height: 1.688rem;
  }

  &__body .motto {
    font-family: "PT Serif";
    font-weight: 700;
    font-size: 1.125rem;
    letter-spacing: 0.045rem;
    line-height: 1.813rem;
  }

  .contactBroker {
    margin: {
      top: 6.5rem;
      bottom: 0;
    }
    padding: {
      top: 6.5rem;
      bottom: 12rem
    }
  }

  .references {
    margin: 3rem 0;
    padding: 0;
  }

  .contactBroker {
    margin: 3rem 0;
    padding: 0;
    color: $darkGrey;

    .form-control {
      height: 3.375rem;
      font-size: 0.938rem;
      font-weight: 400;
      letter-spacing: 0.019rem;
      background: unset;
    }

    textarea {
      background: unset;
      min-height: 210px;
      padding: .6rem;
    }

    textarea::placeholder {
      padding: .6rem;
    }
  }

  .container-fluid {
    .heading3 {
      font-size: 1.5rem;
      font-weight: 700;
      line-height: 2.25rem;
    }
  }

  .youMayLikeIt {
    margin-top: 2rem;
  }

  .bestBrokers {
    margin-top: 5rem;
  }

  @include media-breakpoint-up(md) {
    .no-agency-image {
      height: 25.25rem !important;
    }
    .contactBroker {
      margin: {
        top: 6.5rem;
        bottom: 0;
      }
      padding: {
        top: 6.5rem;
        bottom: 12rem
      }
    }
    .references {
      margin: 6.5rem 0;
      padding: 5.5rem 0;
    }
    .container {
      padding: 0;
    }
    &__body .motto {
      font-size: 1.375rem;
      letter-spacing: 0.055rem;
      line-height: 2rem;
    }
    .section-padding {
      padding: 0 5rem;
    }
    &__body {
      margin-top: 5.625rem;
      background: white;
      padding: 0 15px;
      max-width: 1200px;
    }
    &__body .heading4 {
      margin-bottom: 3.125rem;
    }
    .author {
      display: none;
    }
    .top {
      flex-direction: row;
      height: 37.5rem;

      .image {
        flex: 1 1 50%;
      }

      .author-detail {
        flex: 1 1 50%;
        padding: 0 6rem;
        display: flex;
        margin-bottom: 0;
        justify-content: flex-start;
        align-items: center;

        .heading2 {
          font-family: "PT Serif";
          font-size: 2.25rem;
          font-weight: 700;
          line-height: 3.063rem;
        }

        .heading4 {
          font-size: 0.938rem;
          font-weight: 600;
          letter-spacing: 0.038rem;
          line-height: 2.25rem;
          text-transform: uppercase;
        }
      }
    }
  }
}


.forum .category {
  padding: {
    left: 1rem;
    right: 1rem;
    top: 0;
    bottom: 2.5rem;
  }

  &:after {
    content: '';
    position: absolute;
    height: 1px;
    left: 0;
    right: 0;
    background: #dedede;
  }

  color: $darkGrey;

  .button {
    padding-left: 2rem;
    height: 44px;

    .plusIco {
      position: absolute;
      left: 1rem;
      top: 50%;
      transform: translateY(-50%);
    }
  }

  @include media-breakpoint-up(sm) {
    padding: {
      left: 0;
      right: 0;
      top: 0;
      bottom: 2.5rem;
    };
    .heading2 {
      font-size: 1.125rem;
      font-weight: 700;
      letter-spacing: 0.023rem;
      line-height: 2.25rem;
      margin-bottom: 1rem;
    }
  }
}

.forum .heading2--secondary {
  margin-bottom: 2.5rem;
  font-size: 0.938rem;
}

.forum .card-columns, .broker-archive .card-columns {
  column-count: 1;
  @include media-breakpoint-up(lg) {
    column-count: 2;
    column-gap: 2rem;
  }
}

.forum .body .card {
  background: unset;
  border: unset;
  margin: 0;
}

.forum .body .primaryCard {
  margin-bottom: 2rem;

  .button {
    margin: 1rem 0;
  }

  @include media-breakpoint-up(sm) {
    .heading3 {
      font-family: "PT Serif";
      color: $darkGrey;
      font-size: 1.5rem;
      font-weight: 700;
      letter-spacing: 0.045rem;
      line-height: 2.5rem;
      transition: all 300ms;

      &:hover {
        text-decoration: underline;
        color: $darkGrey;
      }
    }
  }
}

.forum .searchForm input {
  height: 2.75rem;
  font-size: 0.938rem;
  font-weight: 400;
  letter-spacing: 0.019rem;
  background: white;
}


.forum .body .container {
  display: flex;
  flex-wrap: wrap;
}


.forum__category {
  position: relative;
  padding: 2.5rem 0;
  margin-top: 5rem;

  .searchForm input {
    height: 2.75rem;
    font-size: 0.938rem;
    font-weight: 400;
    letter-spacing: 0.019rem;
    background: white;
  }

  &:after {
    content: '';
    position: absolute;
    height: 1px;
    left: 0;
    right: 0;
    bottom: 0;
    background: #dedede;
  }

  &:before {
    content: '';
    position: absolute;
    height: 1px;
    left: 0;
    right: 0;
    top: 0;
    background: #dedede;
  }

  .heading2 {
    margin-bottom: 2.5rem;
  }

  ul {
    padding: 0;
    margin: 0;
    list-style: none;
  }

  li {
    display: inline-block;
  }

  li a {
    color: $darkGrey;
    display: inline-block;
    font-size: 1.25rem;
    font-weight: 400;
    line-height: 1.938rem;
    margin-right: .6rem;
    margin-bottom: .4rem;
    transition: all 300ms;

    &:hover {
      text-decoration: underline;

    }
  }
}

.forum-category .header {
  margin-bottom: 2.5rem
}

;
.forum-category .category {
  padding-bottom: 2.5rem;

  &:after {
    content: '';
    position: absolute;
    height: 1px;
    left: 0;
    right: 0;
    background: #dedede;
  }

  .searchForm input {
    height: 2.75rem;
  }

  .searchForm {
    width: 100%;
  }

  .select select {
    padding: 0 1rem;
    min-width: 17.5rem;
    height: 2.75rem;
    border-radius: 0.313rem;
    border: 0.063rem solid $darkGrey;
    background-color: #ffffff;
  }

  .button {
    width: 100%;
  }

  @include media-breakpoint-up(sm) {
    .button {
      width: 9.938rem;
      text-align: center;
    }
    .searchForm {
      width: auto;
    }
  }
}

.forum-category .forum__category ul li a {
  font-size: 0.938rem;
  font-weight: 400;
  line-height: 2.25rem;
  text-decoration: underline;
  margin-right: .6rem;
  margin-bottom: 0;

}

.forum-category .body .container-secondary {
  @include media-breakpoint-up(sm) {
    .heading2 {
      font-size: 1.5rem;
      font-weight: 600;
      line-height: 2.25rem;
    }
    .heading3 {
      font-size: 0.938rem;
      font-weight: 700;
      letter-spacing: 0.038rem;
      line-height: 2.25rem;
      text-transform: uppercase;
    }
  }
}


.boxes {
  color: $darkGrey;

  .container-fluid {
    padding: 0 15px;
  }

  .heading2 {
    font-size: 1.25rem;
    font-weight: 700;
    line-height: 1.75rem;
    margin-bottom: 3rem;
  }

  .box img {
    width: 8.75rem;
    margin-bottom: 1.8rem;
  }

  .col-md-4 {
    display: flex;
    align-items: center;
    justify-content: center;
  }

  .box {
    text-align: center;
    padding: 1rem 3rem;
    position: relative;
  }

  .circle-step {
    display: block;
    width: 120px;
    height: 120px;
    background-color: #eef0f0;
    border-radius: 50%;
    line-height: 120px;
    text-align: center;
    font-size: 33px;
    margin: auto;
    margin-bottom: 20px;
  }

  @include media-breakpoint-up(xl) {
    .box--arrow:after {
      content: "";
      position: absolute;
      right: 0;
      top: 25%;
      transform: translateY(-50%) translateX(100%);
      background: url("../images/box_arrow.svg");
      width: 3.75rem;
      height: 1.25rem;
    }
  }

  .box--arrow-four:after {
    right: 15px;
    top: 22%;
  }

  @include media-breakpoint-up(sm) {
    padding-bottom: 6rem;
    .heading2 {
      margin-top: 4rem;
      font-size: 1.5rem;
      font-weight: 700;
      line-height: 2.313rem;
    }
    .paragraph {
      margin: 0 auto;
      max-width: 19rem;
    }
  }
}

.form {
  .button:disabled {
    background: #cfcfcf;
    border: 1px solid #cfcfcf;
  }

  .map-active {
    fill: $blue;
  }

  svg {
    transition: all 300ms;
  }

  color: $darkGrey;
  background-color: #f4f4f4;
  padding: 2.5rem 15px;

  .heading2 {
    margin-bottom: 3rem;
  }

  .layout {
    box-shadow: 5.625rem 3.125rem 5.625rem rgba(0, 0, 0, 0.16);
    border-radius: 0.938rem;
    background-color: #ffffff;
    padding: 1.25rem;
  }

  .header {
    margin-top: .8rem;
  }

  .header .paragraph {
    max-width: 22.5rem;
  }

  .header .heading3 {
    margin-bottom: 1rem;
    font-weight: 700;

    span {
      font-weight: 400;
    }
  }

  .custom-control-label {
    cursor: pointer
  }

  .body .heading3 {
    font-size: 0.938rem;
    font-weight: 700;
    letter-spacing: 0.038rem;
    line-height: 2.25rem;
  }

  .cta {
    display: block;
    margin-top: 2rem;
    text-align: right;
    @include media-breakpoint-up(sm) {
      margin-top: 4rem;
    }
  }

  .cta .button:not(.button--prev):not(.button--done) {
    padding-right: 3rem;

    .arrowIco {
      position: absolute;
      right: 1rem;
      top: 50%;
      transform: translateY(-50%);
    }
  }

  .step-1 {
    .district, .country {
      column-count: 2;
    }

    .district .custom-control, .country .custom-control {
      display: block;
      margin-bottom: .5rem;
    }

    @include media-breakpoint-up(lg) {
      .district, .country {
        column-count: 3;
      }
      .district .custom-control, .country .custom-control {
        display: block;
        margin-bottom: .5rem;
      }
    }
  }

  .step-2 .form-group {
    margin: 0
  }

  .step-2 label {
    display: flex;
    justify-content: center;
    align-items: center;
    cursor: pointer;
    margin: .2rem;
    width: 6.75rem;
    height: 6.75rem;
    color: $darkGrey;
    text-align: center;
    font-size: 0.938rem;
    font-weight: 400;
    line-height: 1.563rem;
    border-radius: 0.625rem;
    border: 0.063rem solid rgba(72, 72, 72, 0.5);
    background-color: #ffffff;
    @include media-breakpoint-up(sm) {
      margin-right: 1rem;
      width: 8.75rem;
      height: 8.75rem;
    }
  }

  .step-2 .body {
    text-align: center;
  }

  @include media-breakpoint-up(lg) {
    .step-2 .body {
      margin-right: 5rem
    }
  }
  @include media-breakpoint-up(sm) {
    .step-2 .body {
      display: flex;
      flex-wrap: wrap;
    }
    .step-2 {
      label {
        margin-right: 1rem;
        margin-bottom: 1rem;
        transition: all 300ms;
      }

      label .label-type {
        transition: all 300ms;
        transform: translateY(0);
      }
    }
  }

  .radio-input:checked ~ label {
    border: 4px solid $blue;
    @include media-breakpoint-up(sm) {
      box-shadow: 0 1.25rem 1.25rem rgba(0, 0, 0, 0.16);
      .label-type {
        transform: translateY(-5px);
      }
    }

  }

  .step-3 label {
    width: 100%
  }

  .step-3 .form-control {
    max-width: 22.5rem;
    width: 100%;
    height: 3.375rem;
    border-radius: 0.313rem;
    border: 0.063rem solid $darkGrey;
    background-color: #ffffff;
    font-size: 0.938rem;
    font-weight: 400;
    letter-spacing: 0.019rem;
  }

  .step-4 .heading2 {
    font-size: 1.5rem;
    line-height: 2.375rem;
    font-weight: 400;
  }

  .step-4 .paragraph {
    font-size: 0.938rem;
    font-weight: 400;
    line-height: 1.563rem;
  }

  .response {
    margin-top: 2rem;
    text-align: center;

    &:before {
      content: '';
      position: absolute;
      height: 1px;
      left: 0;
      right: 0;
      background: #dedede;
    }

    h5 {
      color: $darkGrey;
      font-size: 0.938rem;
      font-weight: 700;
      letter-spacing: 0.038rem;
      line-height: 2.25rem;
    }
  }

  @include media-breakpoint-up(sm) {
    padding: 5.625rem;
    .header {
      margin: 0;

      .heading3 {
        font-size: 1.5rem;
        font-weight: 700;
        line-height: 2.375rem;
      }

      .heading3 span {
        font-weight: 400;
      }

      .paragraph {
        margin-top: 2rem;
        font-size: 0.938rem;
        font-weight: 400;
        line-height: 1.563rem;
      }
    }
    .custom-control {
      margin-bottom: .5rem;
    }
    .layout {
      padding: {
        left: 3.75rem;
        right: 3.75rem;
        top: 5rem;
        bottom: 3.75rem;
      }
    }
    .cta .button {
      padding: {
        top: 1.2rem;
        bottom: 1.2rem
      };
      box-shadow: 0 1.25rem 1.25rem rgba(0, 0, 0, 0.16);
    }
  }
}


.form .form__progress-bar {
  ul {
    text-align: center;
    padding: 0;
    margin-top: 2rem;
    list-style: none;
    display: flex;
  }

  li {
    display: inline-block;
    text-align: center;
    margin: 0 .6rem;

  }

  li p {
    margin-top: .5rem;
    font-size: 0.813rem;
    font-weight: 400;
    line-height: 1.25rem;
    margin-bottom: 0;
    max-width: 5.625rem;;
  }

  .dot {
    margin: 0 auto;
    width: 1.875rem;
    height: 1.875rem;
    border: 0.125rem solid $darkGrey;
    border-radius: 50%;
    display: block;
    position: relative;
    background-color: #f4f4f4;

    &:after {
      content: "";
      right: 0;
      top: 50%;
      transform: translateY(-50%) translateX(100%);
      width: 4rem;
      height: 0.063rem;
      background: $darkGrey;
      position: absolute;
    }
  }

  .dot--active {
    background: url("../images/dot_active.svg");
    border: 0 !important;
  }

  .dot--done {
    background: url("../images/dot_done.svg");
    border: 0 !important;
  }

  @include media-breakpoint-up(sm) {
    li {
      margin: 0 1.2rem;
    }
    .dot {
      margin: 0 auto;
      width: 1.875rem;
      height: 1.875rem;
      border: 0.125rem solid $darkGrey;
      border-radius: 50%;
      display: block;
      position: relative;
      background-color: #f4f4f4;

      &:after {
        content: "";
        right: 0;
        top: 50%;
        transform: translateY(-50%) translateX(100%);
        width: 5.875rem;
        height: 0.063rem;
        background: $darkGrey;
        position: absolute;
      }
    }
  }
}

.forum-discussion {

  h5 {
    color: $darkGrey;
    font-size: 1.5rem;
  }

  color: $darkGrey;

  .heading2--primary {
    margin-top: 3rem;
  }

  .info-bar {

    margin-top: 1rem;
    display: block;
  }

  &__header .button {
    padding-left: 3rem;

    .plusIco {
      position: absolute;
      left: 1rem;
      top: 50%;
      transform: translateY(-50%);
    }
  }

  &__header {
    a:not(.button) {
      color: $darkGrey;
      text-decoration: none;

      &:hover {
        text-decoration: underline;
      }
    }

    @include media-breakpoint-down(md) {
      .button {
        margin-bottom: 2rem;
      }
    }

  }

  @include media-breakpoint-up(sm) {
    .info-bar {
      margin-top: 3.5rem;
    }

    .heading2--primary {
      margin-top: 5rem;
    }

    &__header {
      .heading1 {
        max-width: 47.563rem;
        margin-bottom: 1rem;
      }

      .paragraph {
        max-width: 47.563rem;
        font-size: 0.938rem;
        font-weight: 400;
        line-height: 1.563rem;
        margin-bottom: 3.5rem;
      }
    }
  }
}

.unKnowBroker .top {
  color: $darkGrey;
  width: 100%;
  background-color: #f6f6f6;
  height: auto;
  padding: 2rem;
  margin-bottom: 6.5rem;

  .avatar {
    width: 10rem;
    height: 10rem;
    object-position: top center;
    object-fit: cover;
    box-shadow: 0 0.188rem 3.75rem rgba(0, 0, 0, 0.08);
    border-radius: 50%;
  }

  .button {
    margin-bottom: .8rem;
  }

  .heading2 {
    font-size: 0.938rem;
    font-weight: 600;
    letter-spacing: 0.038rem;
    line-height: 2.25rem;
    text-transform: uppercase;
    margin: 1.75rem 0;
  }

  .heading1 {
    margin-bottom: 1.75rem;
  }

  &__body {
    max-width: 25rem;
    width: 100%;
  }

  @include media-breakpoint-up(md) {
    min-height: 37.5rem;
    padding-top: 5.675rem;
  }
}

.unKnowBroker .heading2--secondary {
  margin-top: 3rem;
  font-size: 0.938rem;
  font-weight: 700;
  letter-spacing: 0.019rem;
  line-height: 2.25rem;
  text-transform: uppercase;
}


.price__options {
  display: flex;
  flex-wrap: wrap;
  justify-content: center;

  h2 {
    color: $darkGrey;
    font-size: 1.25rem;
    line-height: 2.938rem;
    font-weight: 600;
  }

  h4 {
    font-size: 0.813rem;
    font-weight: 400;
  }

  .button {
    min-width: unset;
  }

  div {
    padding: .5rem;
    width: 120px;
    border-right: 1px solid #d8d8d8;

  }

  @include media-breakpoint-up(sm) {
    justify-content: flex-end;
    h2 {
      font-size: 1.5rem;
      line-height: 2.938rem;
    }
    h4 {
      font-size: 0.938rem;
    }
    div {
      padding: 1rem;
      width: 200px;
      border: 0;
    }
  }
}

.price .accordion {
  h2 {
    text-align: center;
    color: $darkGrey;
    font-size: 0.813rem;
    font-weight: 700;
    letter-spacing: 0.033rem;
    line-height: 1.938rem;
    text-transform: uppercase;
  }

  h2 img {
    float: left;
    transition: all 300ms;
  }

  .collapsed img {
    transform: rotate(-180deg);
  }

  .card-body {
    padding: 0;
  }

  .card-header {
    margin: {
      top: 1rem;
      bottom: .5rem;
    }
    border: {
      right: 0;
      left: 0;
      top: 0;
      bottom: 3px solid $blue;
    };
    background: white;
  }

  .card {
    margin: 0;
    border: 0;
  }

  table {
    color: $darkGrey;
    font-size: 0.813rem;
    font-weight: 400;
    letter-spacing: 0.016rem;
    line-height: 1.938rem;
    text-align: center;
  }

  .table-border {
    border: 1px solid #d8d8d8;
    border-top: 0;

  }

  .table th, .table td {
    padding: .5rem;
  }

  .table-1 {
    width: 120px;
  }

  @include media-breakpoint-up(sm) {

    .card-header {
      padding: 0.75rem 0;
    }
    h2 {
      text-align: left;
    }
    h2 img {
      margin-right: 1rem;
    }
    .table th, .table td {
      border: 0;
      padding: .75rem;
    }
    table {
      text-align: left;
    }
    .table tr {
      border-bottom: 1px solid #d8d8d8;
    }
    .table-1 {
      text-align: center;
      width: 200px;
    }
  }
}

.price .faq {
  margin: 2rem 0;
  @include media-breakpoint-up(sm) {
    margin: 7rem 0;
  }
}

.price .accordionFaq {
  color: $darkGrey;
  margin-top: 3rem;

  h2 {
    font-size: 0.938rem;
    font-weight: 600;
    line-height: 1.563rem;
  }

  img {
    transform: rotate(0deg);
    transition: all 300ms;
  }

  .collapsed img {
    transform: rotate(-180deg);
  }

  .card {
    background: white;
    margin: {
      bottom: 1rem;
      left: 0;
      right: 0;
    }
    border: 0;
    border-radius: 0;
  }

  .card-header {
    padding: 0.75rem 0;
    background: 0;
    border: 0;
    border-bottom: 1px solid #d8d8d8;
    display: flex;
    align-items: center;
    justify-content: space-between;
  }

  .card-body {
    padding: 0.75rem 0;
  }
}

.brokers .district {
  column-count: 3;
}

.broker-rating {
  color: $darkGrey;

  &__body {
    margin: 3.125rem 0;
  }

  .pagination {
    padding-bottom: 5rem;
    margin-bottom: 0;
  }

  .referenceCard {
    margin-bottom: 2rem;
  }

  .broker-rating__body .rating {
    margin-top: .5rem !important;
  }

  .header {
    background-color: #f2f2f2;
    padding-top: 5rem;
    padding-bottom: 4rem;

    .heading2--primary {
      margin-bottom: 1.8rem;
      font-size: 0.938rem;
      font-weight: 700;
      letter-spacing: 0.038rem;
      line-height: 2.25rem;
    }

    .bestBrokers .heading2 {
      font-family: "PT Serif";
      font-size: 1.625rem;
      font-weight: 700;
      letter-spacing: 0.033rem;
      line-height: 2.313rem;
    }

    .bestBrokers .rating {
      margin-top: 0 !important;
    }

    .rounded-circle {
      width: 7.5rem;
      height: 7.5rem;
      object-fit: cover;
    }

    .button--primary {
      margin-top: 1rem;
    }

    @include media-breakpoint-up(sm) {
      .button--primary {
        margin-top: .4rem;
      }
    }
  }
}

.text-page {
  .banner__img {
    position: absolute;
    left: 0;
    top: 0;
    width: 100%;
    height: 20rem;
    object-fit: cover;
  }
}

.specialization {
  div.main {
    margin: .5rem 0;
    padding: .5rem 0;
    border-bottom: 1px solid rgba(#000, .25);
    display: flex;
    flex-direction: row;
  }

  div.left {
    flex: 1 1 50%;
    display: inline-block;

    h5 {
      font-size: 0.938rem;
      font-weight: 400;
      line-height: 1.313rem;
      margin: 0;
    }
  }

  div.right {
    flex: 1 1 50%;
    display: inline-block;

    p, span {
      margin: 0;
      font-weight: 400;
      font-size: 0.938rem;
    }

    a {
      color: #2977c9;
      text-decoration: none;

      &:hover {
        text-decoration: underline;
      }
    }
  }
}

.captcha-form {
  .form-group {
    margin-bottom: 0;
  }
}

@media only screen and (max-width: 350px) {
  .form .custom-control-label {
    font-size: .8rem;
  }
}

.broker-archive {
  .news__otherPost .heading2 {
    margin: .8rem 0;
  }

  .news__mainPost__body .heading2 {
    margin: .8rem 0;
  }
}


.brokerCompany-archive {
  .header {
    @include media-breakpoint-up(sm) {
      text-align: left;
      background-image: url("../images/brokers_banner.png");
      background-size: cover;
      background-position: 40px 20%;
      padding-bottom: 0;
      min-height: 20rem;
    }
  }
}

.unKnowBroker {
  .contactBroker {
    display: none;

    margin: {
      top: 6.5rem;
      bottom: 0;
    }
    padding: {
      top: 6.5rem;
      bottom: 12rem
    }
  }

  .contactBroker {
    margin: 3rem 0;
    padding: 0;

    color: $darkGrey;

    .form-control {
      height: 3.375rem;
      font-size: 0.938rem;
      font-weight: 400;
      letter-spacing: 0.019rem;
      background: unset;
    }

    textarea {
      background: unset;
      min-height: 210px;
    }
  }

  @include media-breakpoint-up(md) {
    .contactBroker {
      margin: {
        top: 6.5rem;
        bottom: 0;
      }
      padding: {
        top: 6.5rem;
        bottom: 12rem
      }
    }
  }

  .section-padding {
    padding: 0 5rem;
    margin: 0;
  }
}

#registration .form-check-label {
    font-size: 14px;
}
